// -----------------------------------------------------------------------------
// Navbar
// -----------------------------------------------------------------------------

.navbar{
  min-height: 64px;
  margin-bottom: 0
}
.navbar-brand { padding: 0; }
.navbar-nav > li > a {
  line-height: 50px;
  font-size: 1.4em;
  color: $text-color;
}
.navbar-toggle {
  padding: 5px;
  margin-top: 7px;
  border-radius: 0;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 6px;
}

.navbar-default {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid $brand-color;
}
.navbar-default .navbar-toggle{
  border-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus{
  background-color: #fff;
}

.navbar-brand > img {
  display: block;
  position: absolute;
  top: 50%;
  height: 56px;
  margin-top: -28px; /* Half the height */
}

.navbar-nav > .stacked > a {
  line-height: 20px;
  font-size: 1.4em;
  padding: 10px;
}

.call-now {
  font-family: $font__regular;
  color: $brand-color;
  font-weight: 600;
}
.nav .fa-phone {
  color: $brand-color;
}
.hours {
  padding-left: 38px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 7px;
  margin-bottom: -5px;
  display: block;
}

.navbar-default .navbar-nav > li > a.btn {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}
.navbar-default .navbar-nav > li > a.btn .fa {
  color: #fff;
}
.navbar-default .navbar-nav > li > a.btn:hover {
  background-color: $brand-color-dark;
}

.nav-pills > li > a {
  border-radius: 0;
  font-size: 4em;
  color: #000;
  font-weight: 600;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: $brand-color;
  background-color: transparent;
}

@media (max-width: 1184px) {
  .navbar {
    min-height: 48px;
  }
  .navbar-nav {
    margin: 0 -15px;
  }

  .navbar-brand > img{
    height: 36px;
    margin-top: -18px;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus{
    background-color: $brand-color;
    color: #fff;
  }
}

@media (max-width: 767px) {
  .navbar-brand > img {
    height: 42px;
    margin-top: 3px;
    top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .navbar .stacked {
      display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .navbar-nav > li > a {
    font-size: 1.2em;
  }
}
