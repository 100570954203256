// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

body {
  color: $text-color;
  font-weight: normal;
  font-family: $font__regular;
  font-size: 14px;
  line-height: 1.4;
}

p {
   font-size: 18px;
}

h1, h2, h3, h4, h5 {
  font-family: $font__compact;
}
h1 {font-size: 48px; }
h2 {font-size: 36px; }
h3 {font-size: 26px; font-weight: 400; }
h4 {font-size: 20px; }
h5 {font-size: 18px; line-height: 1.3 }

@media (max-width: 767px){
  h1 { font-size: 40px; }
}
@media (min-width: 768px) and (max-width: 1024px){
  h1 { font-size: 42px; }
}
@media (max-width: 414px){
  h1 { font-size: 36px; }
  h2 { font-size: 30px; }
}
