// -----------------------------------------------------------------------------
// Base Styles
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  transition: all 0.25s;
  &:focus{
    outline: 0;
  }
  @include on-event {
    color: $brand-color-dark;
    text-decoration: none;
  }
}
:focus{
  outline: 0;
}

legend {
  border-bottom: 0;
}

.panel-title a {
  @include on-event {
    color: $text-color;
    text-decoration: none;
  }
}

.list {
  padding-left: 1em;
}
.list li {
  margin: 0.5em 0;
  list-style: none;
  font-size: initial;
}
