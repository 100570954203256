.avatars {
  display: flex;
  justify-content: center;
}
.avatar-item {
  align-items: center;
  display: flex;
  justify-content: center;
}
.avatar-image {
  margin: 0 0.5em;
}
.avatar-image .small {
    max-width: 15vw;
}
