// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

.btn,
.btn-lg,
.btn-xl,
.btn-group-lg > .btn{
  border-radius: 30px;
  border: 0;
}
.btn-xl {
  padding: 10px 60px;
  font-size: 18px;
}
.btn:focus{
  outline: 0;
}
.btn-brand{
  background-color: $brand-color;
  color: #fff;
  &:hover,
  &:focus,
  &:active{
    color: #fff;
    background-color: shade($brand-color, 10%);
    border-color: shade($brand-color, 10%);
  }
}
.btn-brand-inverse {
    background-color: white;
    border: 1px solid $brand-color;
    color: $brand-color;
    &:hover,
    &:focus,
    &:active {
      color: shade($brand-color, 10%);
    }
}

.btn-redline {
  background-color: $brand-redline;
  color: #fff;
}
.btn-redline:hover, 
.btn-redline:focus, 
.btn-redline:active {
  color: #fff;
  background-color: $brand-redline-dark;
  border-color: $brand-redline-dark;
}

.btn-redline-inverse {
  background-color: #fff;
  color: $brand-redline;
}
.btn-redline-inverse:hover, 
.btn-redline-inverse:focus, 
.btn-redline-inverse:active {
  color: $brand-redline-dark;
  background-color: #fff;
  border-color: #fff;
}

.btn-round {
    font-size: 22px;
    border-radius: 30px;
    padding: 10px 0;
}
.btn-invisible {
  border: 0;
}
.btn-slate{
  background-color: $baby-slate;
  color: #fff;
  &:hover,
  &:focus,
  &:active{
    color: #fff;
    background-color: shade($baby-slate, 10%);
    border-color: shade($baby-slate, 10%);
  }
}
.btn-cta-wide {
  height: 60px;
  font-size: 22px;
  margin: 2em auto;
  max-width: 800px;
  border-radius: 30px;
}
.btn-idk {
  margin-bottom: 40px;
  margin-top: 10px;
  border-radius: 30px;
}
.footer .newsletter .btn {
  border-radius: 0;
}
.btn-fat {
  padding-right: 4em;
  padding-left: 4em;
}
.btn-auto{
  width: fit-content;
}

@media (max-width: 414px){
  .btn-lg {
    font-size: 14px;
  }
}
@media (max-width: 767px){
  .btn-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
