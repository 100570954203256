// -----------------------------------------------------------------------------
// Lightbox
// -----------------------------------------------------------------------------

.lightbox-backdrop {
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background:#000;
  opacity: .0;
  filter:alpha(opacity=0);
  z-index:110;
  display:none;
}

.lightbox-box {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#ffffff;
  z-index:111;
  padding:0;
  -moz-box-shadow:0px 0px 5px #444444;
  -webkit-box-shadow:0px 0px 5px #444444;
  box-shadow:0px 0px 5px #444444;
  display:none;
}

.lightbox-close {
  cursor:pointer;
} 