// -----------------------------------------------------------------------------
// Carousel
// -----------------------------------------------------------------------------

.banner {
  display: flex;
  justify-content: center;
}
.banner-col {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px){
  .banner {
    display: block;
  }
}

.slick-prev, .slick-next {
  z-index: 9;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slider,
.carousel-banner,
.carousel-whyus,
.carousel-webinar,
.carousel-support,
.carousel-spotlight,
.carousel-cabinet {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
}

.slider.slick-initialized,
.carousel-banner.slick-initialized,
.carousel-whyus.slick-initialized,
.carousel-webinar.slick-initialized,
.carousel-support.slick-initialized,
.carousel-spotlight.slick-initialized,
.carousel-cabinet.slick-initialized {
  visibility: visible;
  opacity: 1;    
}

#carousel-home {
  height: max-content;
  ul {
    padding: 1.5em 0;
  }
  li {
    list-style: none;
    font-size: 16px;
  }
  h3 {
    margin-top: 0;
  }
  .carousel-content {
    text-align: center;
  }
  .bg-banner {
    background-color: whitesmoke;
    height: auto;
  }
  .bg-night {
    background-color: #060f1e;
    height: auto;
    color: #fff;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
  .slick-dots {
    bottom: 0;
  }

  @media screen and (max-width: 414px){
    .carousel-content {
        padding: 0;
      ul {
        display: none;
      }
    }
    .slick-slide img {
      max-width: 260px;
    }
  }
  // @media screen and (min-width: 768px) and (max-width: 991px) {
  //   .bg-banner {
  //     height: 400px;
  //   }
  // }
  @media screen and (max-width: 767px) {
    // .bg-banner {
    //   height: auto;
    // }
    .slick-dots {
      display: none !important;
    }
    .slick-slide img {
      max-width: 300px;
    }
  }
}

#carousel-spotlight {
  .slick-next {
    right: -30px;
  }
  .slick-prev {
    left: -30px;
  }
}

#carousel-webinar,
#carousel-support {
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  @media screen and (min-width: 768px){
    .slick-prev, .slick-next {
      display: none !important;
    }
  }
}

#carousel-whyus {
  .svg-icon img {
    height: 200px;
    width: 200px;
    filter: invert(38%) sepia(95%) saturate(828%) hue-rotate(339deg) brightness(92%) contrast(99%);
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  .slick-prev:before, .slick-next:before {
    color: $brand-color;
  }
}
