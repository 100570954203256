// -----------------------------------------------------------------------------
// @font-face declarations
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/OpenSans-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/OpenSans-SemiboldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
}
