// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hidden {
  display: none;
}
.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.center-text {
  text-align: center;
}
.justify-text {
  text-align: justify;
}
.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.underline,
.underline a,
.underline a:hover,
.underline a:focus
  {text-decoration: underline;}

.all-caps, .allcaps {
  text-transform: uppercase;
}  
.featured {
  font-size: 1em;
  font-weight: 600;
}
.text-xl {
  font-size: 36px;
  font-weight: 400;
}
.lead {
  font-size: 20px;
}
figcaption {
  color: #6d6d6d;
  margin-top: 0.75em;
}

.img-full-width {
  width: 100%;
  height: auto;
}
.img-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.img-border {
  border: 1px solid #999;
}
.img-border-fun{
  border: 3px solid $brand-color;
}
.img-rounder {
  border-radius: 30px;
}
.img-invert {
  filter: invert(100%);
}

.pb-100 {
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}

.px-10{
  padding-left: 10px;
  padding-right: 10px;
}
.px-15{
  padding-left: 15px;
  padding-right: 15px;
}
.px-1{
  padding-left: 1em;
  padding-right: 1em;
}
.px-2{
  padding-left: 2em;
  padding-right: 2em;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.py-4 {
  padding-top: 4em;
  padding-bottom: 4em;
}
.py-5 {
  padding-top: 5em;
  padding-bottom: 5em;
}
.py-6 {
  padding-top: 6em;
  padding-bottom: 6em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.my-3 {
  margin-top: 3em;
  margin-bottom: 3em;
}
.my-4 {
  margin-top: 4em;
  margin-bottom: 4em;
}
.my-5 {
  margin-top: 5em;
  margin-bottom: 5em;
}
.my-10 {
  margin: 10px 0;
}
.my-20 {
  margin: 20px 0;
}
.my-50 {
  margin: 50px 0;
}
.my-100 {
  margin: 100px 0;
}
.mx-05{
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.mx-1{
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2{
  margin-left: 2em;
  margin-right: 2em;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mt-0 {
  margin-top: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-100 {
  margin-top: 100px;
}
.mb--3 {
  margin-bottom: -3em;
}
.mt--1 {
  margin-top: -1em;
}


.svg-icon {
    display: inline-flex;
    align-self: center;
}
.svg-icon svg,
.icon img {
    height: 1em;
    width: 1em;
    fill: currentColor;
}
.svg-icon.baseline svg {
  height: 6em;
  width: 6em;
}
.svg-icon.baseline svg,
.icon img {
    top: .125em;
    position: relative;
}
.svg-icon img {
    height: 120px;
    width: 120px;
}
.svg-icon.baseline-xl svg {
  height: 10em;
  width: 10em;
}

.svg-icon.baseline-jumbo svg {
  height: 15em;
  width: 15em;
}

.ico {
  margin-top: -5px ;
}
.numbers {
  padding-left: 20px;
}
.number {
  font-size: 1.5em;
  margin-left: -20px;
  color: #e15031;
}
.label-wrapper {
  position: relative;
  margin-top: 2em;
}
.label-tr {
  position: absolute;
  top: -2%;
  right: -2%;
  font-size: 1.5em;
  border-radius: 0;
}
.label-br {
  position: absolute;
  bottom: -2%;
  right: -2%;
  font-size: 1.5em;
  border-radius: 0;
}
.nerd-alert:before {
  content:url('/img/co2-nerd.png')
}


@media (max-width: 767px){
  .center-text-mobile {
    text-align: center;
  }
  .xs-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 991px){
  .text-xl {
    font-size: 26px;
  }
  .sm-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
