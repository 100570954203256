// -----------------------------------------------------------------------------
// Cards
// -----------------------------------------------------------------------------

// VARIABLES
$color-header: #0d0d0d;
$color-text: #404040;
$color-btn-text: #fff;
$transition: .2s;

.cards {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 6rem;
  background-color: #f5f5f5;
  font-family: $font__regular;
}
// MAIN CONTENT
.grid {
  display: grid;
  position: relative;
  width: 114rem;
  height: auto !important;
  grid-gap: 6rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(22rem, 1fr)
  );
  align-items: stretch;

  &__item {
    background-color: #fff;
    border-radius: .4rem;
    overflow: hidden;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, .1);
    transition: $transition;

    &:hover {
      transform: translateY(-.5%);
      box-shadow: 0 2rem 4rem rgba(0, 0, 0, .2);
    }
  }
}
@media (max-width: 794px) {
  .grid {
    width: 40rem;
  }
}
@media (max-width: 60em) {
  .grid {
    grid-gap: 3rem;
  }
}

.card {
  &__img {
    display: block;
    width: 100%;
    // height: 30rem;
    // object-fit: cover;
    object-position: 50% 0;
   }

  &__content {
    padding: 3rem 3rem;
    background-color: #fff;
  }

  &__header {
    font-size: 3rem;
    font-weight: 500;
    color: $color-header;
    margin-bottom: 1.5rem;
  }

  &__text {
    font-size: 1.5rem;
    letter-spacing: .1rem;
    line-height: 1.7;
    color: darken($color-text, 1%);
    margin-bottom: 2.5rem;
  }

  &__btn {
    display: block;
    width: 100%;
    padding: 1.5rem;
    font-size: 2rem;
    text-align: center;
    color: $color-btn-text;
    background-color: $brand-color;
    border: none;
    border-radius: .4rem;
    transition: $transition;
    cursor: pointer;

    span {
      margin-left: 1rem;
      transition: $transition;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken( $brand-color, 3%);
      color: $color-btn-text;
        span {
        margin-left: 1.5rem;
      }
    }
  }
}
