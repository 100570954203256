// -----------------------------------------------------------------------------
// Temp
// -----------------------------------------------------------------------------
.gradient-background {
  background: linear-gradient(300deg,#ffe9d5,#fffed5,#ff83a4);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
