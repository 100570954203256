// -----------------------------------------------------------------------------
// Sidebar
// -----------------------------------------------------------------------------

.sidebar .sidebar-menu {
  .panel-heading {
    padding: 0;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .panel-body {
    padding: 15px 0;
  }
  .panel-title {
    font-family: $font__regular;
    font-weight: normal;
    font-size: 14px;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  .panel-title .collapsed {
    &:hover {
      color: $brand-color;
    }
  }
  .panel-heading + .panel-collapse > .panel-body,
  .panel-heading + .panel-collapse > .list-group {
    border-top: 1px solid transparent;
  }
  .badge {
    float: right;
    background-color: $brand-color;
    &:hover{
      background-color: $brand-color;
    }
  }
  .list-group-item:hover {
    background-color: #fff;
  }

}

.no-list {
  padding-left: 1em;
}
.no-list li {
  margin: 0.5em 0;
  list-style: none;
}
