// -----------------------------------------------------------------------------
// FX
// -----------------------------------------------------------------------------

.fx {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.fx img {
  display: block;
  position: relative;
  transition: all 0.35s;
}

.fx-scale:hover img {
  transform: scale(1.2);
}
.fx-blur:hover img {
  filter: blur(2px);
}

.toTop img {
  width: 50px;
}
.toTop{
    cursor: pointer;
    position: fixed;
    right: 40px;
    bottom: 40px;
    padding: 10px;
    z-index: 9999;
    display: none;
  }
.important-info {
  z-index: 9999;
}
