.print-header{display: none;}

@media print {
  * { background: transparent !important;
    color: black;
    text-shadow: none !important;
    filter:none !important;
    -ms-filter: none !important;
  }
  body {
    font-size:11pt;
    line-height: 1.3;
    font-family: Arial, sans-serif;
  }
  a, a:visited {
    text-decoration: underline;
    word-wrap: break-word;
  }
  a[href^="http://"]:after,
  a[href^="https://"]:after {
    content: " (" attr(href) ")";
  }
  a[href^="#"]:after { content: ""; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  img { max-width: 100% }
  @page { margin: 0.3cm; }

  footer,
  header,
  .notification-clock,
  #contact-form,
  #fb-root{
    display: none;
  }

  .product {
    .container,
    .container-fluid{
      display: none;
    }
    .print-header{
      display:block;
      position: relative;}

    .print-estimate{
      position: absolute;
      top: 20px;
      right: 40px;
      h4{
        font-size: 24pt;
        font-weight: bolder;
      }
    }
    .print-contact{
      position: absolute;
      top:40px;
      left:40px;
      img{
        width:120px;
        padding-bottom: 10px;
      }
      p {
        font-size: 11pt;
      }
    }
    .print-fineprint{
      position:absolute;
      left: 40px;
      top: 980px;
      p {
        font-size: 10pt;
        font-weight: bold;
      }
    }
  }

  .cd-builder-steps .summary-list {
    text-align: center;
    margin-top: 160px;
    .product-preview {
      width: 100px;
      display: block;
      margin-top: 40px;
    }
    .summary-accessories li {
      margin: 0;
    }
    li{
      margin: 0;
      padding: 0;
    }

    h3{
      font-size:16pt;
      padding-top: 0.5em;

    }
    h2 {
      font-size:12pt;
      padding: 0;
      margin-bottom: 5px;
      color: $dark-grey;;
    }
  }


}
