
// image + quote box

.quote-box {
  background: $slate-blue;
  border-radius: 30px;
  padding: 2em;
}
.quote-side {
  margin-top: 30%;
  height: auto;
}
.quote-bottom {
  max-width: 360px;
  height: auto;
  margin-top: -4em;
  float: right;
  margin-left: 1em;
}
.quote-box p {
  color: #fff;
}
.quote::before {
  content: "";
  color: #ea5528;
  font-size: 4em;
  position: absolute;
  margin-left: -0.75em;
  margin-top: -0.75em;
}
.quote .svg-icon {
  position: absolute;
  font-size: 4em;
  margin-left: -1em;
  margin-top: -0.25em;
}
.quote .svg-icon svg {
  fill: #ea5528;
}

.quote-flex {
    align-items: center;
    display: flex;
    justify-content: center;
}
.quote-flex-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin: 0 8px;
}
.credit {
  color: #fff;
  padding-left: 35%;
  font-size: 14px;
}
.quote-banner-img {
  max-width: 400px;
  margin-right: 1em;
}

// quote card with product sample
.quote-sample {
  padding-left: 22%;
}
.quote-sample img {
  width: 200px;
  margin-top: -100px;
}
.quote-card {
  border: 3px solid $brand-color;
  margin: 1px;
  background: #fff;
}
.quote-card-text h4 {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 12px;
  margin-bottom: 4px;
}
.quote-card-text p {
  font-size: 16px;
}
.quote-card-avatar {
  margin-left: -15px;
}


@media (max-width: 767px){
  .quote-side {
    margin-top: -4em;
    height: auto;
    max-width: 70vw;
    margin-right: -20%;
  }
  .quote-img {
    max-width: 80vw;
  }
  .quote-bottom {
    height: auto;
    margin-top: -4em;
  }
}

@media (max-width: 558px){
  .quote-sample {
      padding-left: 10%;
  }
  .quote-sample img {
      width: 200px;
      margin-top: -130px;
  }
}

@media (max-width: 474px){
  .quote-sample {
      padding-left: 1%;
  }
  .quote-sample img {
      width: 190px;
      margin-top: -180px;
  }
  .quote-sample img {
      width: 180px;
      margin-top: -250px;
  }
}

@media (max-width: 400px){
  .credit{
    padding-left: 20%;
  }
}
