.pricing-container {
  *zoom: 1;
  max-width: 1140px;
  margin: 1em auto;
}
.pricing-container:after {
  content: "";
  display: table;
  clear: both;
}
.pricing-container.flex {
  display: block;
}
.pricing-list {
  margin: 0 1em 1.625em;
  padding: 1em 1em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: #fff;
}
.pricing-list ul {
  list-style: none;
  padding: 0;
}
.pricing-list li {
  padding: 1em;
  border-bottom: 1px dashed #ddd;
}
.pricing-list .pricing-header {
  border-bottom: none;
  margin: 0;
  padding: .3em .2em .2em;
  font-size: 2em;
  color: #000;
  background: #fff;
  letter-spacing: 0.5px;
}
.pricing-list .pricing-cost {
  margin: 0;
  padding: .2em 0;
  font-size: 3em;
  font-weight: bold;
  color: $brand-color;
}
.pricing-list .pricing-cost.slate {
  color: $slate-blue;
  font-size: 2em;
}
.pricing-list .pricing-footer {
  padding: 1em 0;
  border-bottom: none;
  margin-top: 0.7em;
}
.pricing-list .pricing-icon {
  padding: 20px 0 0 0;
  width: 50%;
  opacity: 1;
}
.pricing-list .pricing-bonus {
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
  vertical-align: middle;
  color: $slate-blue;
}
.pricing-list.flex-col {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.pricing-list .flex-content {
  flex: 1;
}
@media (min-width: 768px) {
  .pricing-list {
    display: inline;
    float: left;
    width: 33.333%;
    margin: 2em 0 0;
  }
  .pricing-list.first {
    border-right: none;
  }
  .pricing-list.second {
    float: right;
    border-left: none;
  }
  .pricing-list.half {
    width: 50%;
  }
  .pricing-container.flex {
    display: flex;
  }
  .pricing-list.highlight {
    margin-top: 0;
    background: #f0f0f0;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  }
  .pricing-list.highlight .pricing-footer {
    padding: 2em 0 3em;
  }
}
