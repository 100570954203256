// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------
.title {
  background: #fff;
  width: 100%;
  margin-top: -20px;
}
.title h1{
  font-family: $font__compact;
  text-align: center;
  padding: 40px 0;
  margin-bottom: 0px;
}
