.application {
  p {
    font-size: 16pt;
    color: $dark-grey;
  }
  .contact-form {
    padding: 20px 0 40px 0;
    background: #eee;
  }
  legend {
    text-align: center;
    font-size: 2.5em;
    color: $brand-color;
    font-family: $font__compact;
  }
  label {
    font-family: $font__compact;
    font-size: 1.25em;
  }
  .form-control{
    border: 1px solid #555;
    border-radius: 0;
  }
  .form-control:focus {
    color: $brand-color;
    border-color: $brand-color;
    box-shadow: none;
  }
  .title {
    background: #f0f0f0;
    width: 100%;
    margin-top: -20px;
  }
  .title h1{
    font-family: $font__compact;
    text-align: center;
    padding: 40px 0;
    color: $brand-color;
  }
  .sample img,
  .samples img {
    margin: 20px auto;
  }
  @media (max-width: 992px) {
    .samples img {
      max-height: 300px;
    }
  }
}