.notification-clock {
    .countdown-banner.dark{
      background:$slate-blue;
    }
    .countdown-banner.redline{
      background:#000;
    }
    .countdown-banner{
      padding: 8px 0px 5px 0px;
      position:relative;
      top:0;
      left:0;
      right:0;
      z-index:100;
      transform: translate3d(0,-100%,0);
      transition:0.2s all ease-in-out;
    }
    .countdown-container {
      text-align:center;
      color:white;
    }
    .countdown-text {
      font-family: arial;
      line-height: 1.3em;
      text-align:left;
      display:inline-block;
      margin:0px 10px;
    }
    .countdown-text a {
      text-decoration: underline;
    }
    .countdown-text a.redline {
      color: white;
    }
    .countdown-text.center {
      text-align: center;
    }
    .countdown-banner.activate,
    .countdown-over.activate{
      transform: translate3d(0,0,0) !important;
      transition:0s all ease-in-out;
    }
    .d-inline-block {
      display: inline-block;
    }
    .clock-item-container {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }
    .clock-item-value {
        color: white;
        padding: 3px 5px;
        border: solid white 1px;
        border-radius: 3px;
        font-weight:bold;
        font-family: arial;
        text-align: center;
        margin-bottom: 3px;
    }
    .clock-item-label {
      font-size: 0.7em;
      font-family: arial;
      margin-bottom: 0;
    }
    .hide-countdown-banner-button {
      position:absolute;
      top:8px;
      right:15px;
      color:white;
      font-size:1.3em;
      cursor:pointer;
    }
    .countdown-coupon-code {
      color: $brand-color;
      font-weight: bold;
    }
    .countdown-coupon-code:hover,
    .countdown-coupon-code:focus {
      color: $brand-color-dark;
    }
    &.jumbo {
      font-size: 3em;
    }
    &.brand .clock-item-value {
      color: $brand-color;
      border: solid $brand-color 1px;
      background-color: whitesmoke;
    }
    &.brand .clock-item-label {
      font-size: 0.5em;
      color: $brand-color;
    }
    &.slate .clock-item-value {
      color: #fff;
      border: solid $slate-blue 1px;
      background-color: $slate-blue;
    }
    &.slate .clock-item-label {
      font-size: 0.5em;
      color: $slate-blue;
    }

    @media (max-width: 480px) {
      .clock-item-container {
        display: inline-block;
        margin-top: 10px;
      }

      .countdown-text {
        text-align:center;
        margin:0px 10px;
      }
    }
}



.notifications {
  background: $slate-blue;
  height: 70px;
  overflow: hidden;
  position: relative;
  transition: all .35s ease-in;
}

.notifications p {
  color: #fff;
  text-align: center;
  padding: 8px 20px;
  margin: 0;
}

.notifications a {
  color: $brand-color;
  text-decoration: none;
  border-bottom: 2px solid;
}

.notify {
  display: block;
  position: absolute;
  color: #fff;
  cursor: pointer;
  width: 25px;
  height: 25px;
  float: right;
  top: 10px;
  right: 0;
  z-index: 1;
}


#notify { display: none; }

#notify:checked ~ .notifications {
  margin-top: -70px;
  transition: all .35s ease-in;
}
#notify:checked ~ .notify {
  margin-top: -70px;
}

@media (min-width: 533px) and (max-width: 991px) {
  .notifications {
    height: 90px;
  }
  #notify:checked ~ .notifications {
    margin-top: -90px;
    transition: all .35s ease-in;
  }
  #notify:checked ~ .notify {
    margin-top: -90px;
  }
}

@media (max-width: 532px) {
  .notifications {
    height: 110px;
  }
  #notify:checked ~ .notifications {
    margin-top: -110px;
    transition: all .35s ease-in;
  }
  #notify:checked ~ .notify {
    margin-top: -110px;
  }
}
