@charset 'UTF-8';

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# 1. Config and helpers
#     Variables
#     Functions
#     Mixins
# 2. Vendors
#     Bootstrap
#     FontAwesome
#     Slick
#     Imagehover
#     ProjectBuilder
#     CSShake
#     InputMask
# 3. Base stuff
#     Base
#     Fonts
#     Typography
#     Helpers
# 4. Layout
#     Flex
#     Header
#     Footer
#     Navigation
#     Forms
# 5. Components
#     Button
#     Dropdown
#     Carousel
#     Fx
#     Lightbox
#     Modal
#     Notification
#     Pricing
#     Cards
#     Sidebar
#     Timeline
# 6. Pages
#     Home
#     Product
#     Subcategory
#     About
#     Application
#     Spotlight
#     Ad
# 7. Themes
#     Default
# 8. Misc
#     Print
#     Temp
#     Shame

--------------------------------------------------------------*/

// 1. Config and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendor/font-awesome',
  'vendor/bootstrap',
  'vendor/slick',
  'vendor/slick-theme',
  'vendor/imagehover',
  'vendor/project-builder',
  'vendor/css-shake';

// 3. Base
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout
@import
  'layout/flex',
  'layout/header',
  'layout/footer',
  'layout/navigation';

// 5. Components
@import
  'components/avatars',
  'components/button',
  'components/dropdown',
  'components/carousel',
  'components/fx',
  'components/lightbox',
  'components/modal',
  'components/notification',
  'components/pricing',
  'components/cards',
  'components/quote',
  'components/sidebar',
  'components/timeline';

// 6. Pages
@import
  'pages/product',
  'pages/subcategory',
  'pages/page',
  'pages/application',
  'pages/spotlight',
  'pages/ad';

// 7. Themes
@import
  'themes/default';

// 8. Misc
@import
  'misc/print',
  'misc/temp',
  'misc/shame';
