// -----------------------------------------------------------------------------
// Customer Spotlight
// -----------------------------------------------------------------------------

.spotlight {
  h2 {
      font-size: 30px;
      color: #212B38;
  }
  h3 {
      font-size: 24px;
      color: #212B38;
  }
  .sandy {
    background-color: $beach-sand-light;
    border-radius: 15px;
  }
  .skyblue {
    background-color: $baby-slate;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2em;
    border-radius: 15px;
  }
}

@media (max-width: 1024px){
  .spotlight h2 {
    font-size: 30px;
  }
}
