.subcategory .title {
  background: #f0f0f0;
  width: 100%;
  margin-top: -20px;
}

.subcategory .title h1{
  font-family: $font__compact;
  text-align: center;
  padding: 20px 0;
  color: $brand-color;
}
.subcategory .filters {
  margin: 20px auto;
  display: block;
}
.subcategory h2 {
  color: #444;
}
.subcategory .machine:hover,
.subcategory .machine:focus{
  cursor: pointer;
}
.subcategory figcaption{
  font-size: 16px;
}
.subcategory .caption{
  text-align: center;
  display: block;
  padding: 5px 0;
}
/* ---- button ---- */

.subcategory .button {
  display: inline-block;
  padding: 0.5em 1.0em;
  background: #EEE;
  border: none;
  // border-radius: 7px;
  // background-image: linear-gradient( to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.2) );
  color: #222;
  font-family: sans-serif;
  font-size: 16px;
  text-shadow: 0 1px white;
  cursor: pointer;
}

.subcategory .button:hover {
  background-color: tint($brand-color, 50%);
  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
  color: #222;
}

.subcategory .button:active,
.subcategory .button.is-checked {
  background-color: $brand-color;
}

.subcategory .button.is-checked {
  color: white;
  text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}

.subcategory .button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}
.subcategory .button.is-disabled,
.subcategory .button.is-disabled:hover,
.subcategory .button.is-disabled:focus,
.subcategory .button.is-disabled:active{
  opacity: 0.25; 
  cursor: not-allowed;
  background: #eee;
}
.subcategory .button.is-disabled.is-checked{
  text-shadow: none;
  color: #222;
}

/* ---- button-group ---- */

.subcategory .button-group:after {
  content: '';
  display: block;
  clear: both;
}

.subcategory .button-group .button {
  float: left;
  border-radius: 0;
  margin-left: 0;
  margin-right: 1px;
}

.subcategory .button-group .button:first-child { border-radius: 0; }
.subcategory .button-group .button:last-child { border-radius: 0; }

/* ---- isotope ---- */

.subcategory .grid {
  background: #fff;
  max-width: 1200px;
  min-height: 300px;
  margin-bottom: 100px;
}

/* clear fix */
.subcategory .grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ui group */

.subcategory .ui-group {
  display: inline-block;
}

.subcategory .ui-group h3 {
  display: block;
  vertical-align: top;
  line-height: 8px;
  margin-right: 0.2em;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.subcategory .ui-group .button-group {
  display: inline-block;
  margin-right: 20px;
}

.subcategory .machine{
  height: 175px;
  width: 213px;
  margin: 5px;
  border: 1px solid $brand-color;
}
.subcategory .power img {
  margin: 20px auto;
}

@media (max-width: 414px){
  .subcategory .machine{
    margin: 5px 0;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .subcategory .ui-group .button-group{
    margin-right: 15px;
    margin-left: 0;
  }
  .subcategory h1 {
    font-size: 24px;
  }
  .subcategory h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .subcategory .ui-group h3 {
    line-height: 4px;
  }
  .subcategory .button {
    padding: 0.5em 0.7em;
  }
}

@media (max-width: 736px) {
  .subcategory h2 {
    font-size: 24px;
    font-weight: 600;
  }
}




