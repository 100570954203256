.page {
  .contact-form {
    padding: 20px 0 40px 0;
    background: #fff;
  }
  legend {
    font-size: 2.5em;
    color: $brand-color;
    font-family: $font__compact;
  }
  label {
    font-family: $font__compact;
    font-size: 1.25em;
    font-weight: 500;
  }
  .form-control{
    border: 1px solid #555;
    border-radius: 0;
  }
  .form-control:focus {
    color: $brand-color;
    border-color: $brand-color;
    box-shadow: none;
  }
  h4{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .footer h4 {
    padding-bottom: 0;
  }
  .last{
    margin-bottom: 5em;
  }
  hr {
    border-top: 1px solid #ccc;
  }
  figcaption {
    margin-top: 1em;
    color: #999999;
  }
  .content-container {
    padding-top: 5em;
    @media (max-width: 767px){
      padding-top: 1em;
    }
  }

  .panel-group.faq  p,
  .panel-group.faq h4 {
    padding: 6px 0 0;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
  }
  .panel-group.faq {
    margin-top: 10px;
  }
  .panel-default > .panel-heading {
    color: $dark-grey;
    background-color: $light-grey;
    border-color: $light-grey;
  }
  .panel-group .panel {
    border-radius: 0;
    margin-bottom: 6px;
  }
  .panel-default {
      border-color: $light-grey;
  }
  .panel-body li {
    font-size: 16px;
  }
  .faq-header {
    color: $brand-color;
    font-size: 24px;
  }
  .contact-hours {
    margin: 0;
    padding: 0;
  }
  .contact-hours li {
    list-style: none;
    font-size: 18px;
  }
  &.lasercats {
    background: url(https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif);
    color: #fff;
  }
  &.lasercats .title {
    background: transparent;
  }
  &.lasercats .footer {
    background: url(https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif);
  }
  &.lasercats .modal-content {
    background: url(https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif);
  }

}
