// -----------------------------------------------------------------------------
// Ad
// -----------------------------------------------------------------------------

.svg-img svg {
  height: auto;
  width: 100%;
  max-width: 200px;
  fill: $brand-color;
}


@media (max-width: 1024px){
  .spotlight h1 {
    font-size: 30px;
  }
}

@media (max-width: 767px){
  .ad img {
    margin-left: auto;
    margin-right: auto;
  }
  .center-xs h3,
  .center-xs p {
    text-align: center;
  }
  .center-xs .svg-img svg {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
